// **************************************************
// Toggle Selected Class On Quiz Block
// **************************************************

const answerItem = document.querySelectorAll('.quiz-block__answers-item');
answerItem.forEach((answerEle) => {
  answerEle.addEventListener('click', (ev) => {
    const thisElem = ev.target.closest('.quiz-block__answers-item');
    if (thisElem.classList.contains('is-selected')) {
      thisElem.classList.remove('is-selected');
    } else {
      answerItem.forEach((el) => el.classList.remove('is-selected'));
      thisElem.classList.add('is-selected');
    }
  });
});
