// *******************************************************************************
// Add class to body loading
// *******************************************************************************

window.addEventListener('DOMContentLoaded', () => {
  document.body.classList.add('is-loaded');
});

// *******************************************************************************
// Toggle Class When Dot Click
// *******************************************************************************

const aboutStepDot = document.querySelectorAll('.about-block__circle');

aboutStepDot.forEach((dotEle) => {
  dotEle.addEventListener('click', (ev) => {
    const thisElem = ev.target.closest('.about-block__circle');
    const parentEle = ev.target.closest('.about-block__step');
    const parentClickedEle = document.querySelectorAll('.about-block__step');

    if (thisElem.classList.contains('is-clicked')) {
      thisElem.classList.remove('is-clicked');
      parentEle.classList.remove('is-clicked');
    } else {
      aboutStepDot.forEach((el) => el.classList.remove('is-clicked'));
      parentClickedEle.forEach((ele) => ele.classList.remove('is-clicked'));
      thisElem.classList.add('is-clicked');
      parentEle.classList.add('is-clicked');
    }
  });
});

// *******************************************************************************
// Scale Image When Dot Click
// *******************************************************************************

attachEvent('.about-block__image .about-block__circle', 'click', (ev) => {
  const dotParentEle = ev.target.closest('.about-block__image');
  dotParentEle.classList.add('is-scaled');
});
